var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "mx": "auto"
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: {
          name: 'client.index'
        }
      }, {
        label: 'Profil',
        href: {
          name: 'client.profile'
        }
      }, {
        label: 'Riwayat Program',
        isCurrent: true
      }]
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "bg": ['#F2F2F2', 'white'],
      "border": ['none', '1px solid #f2f2f2'],
      "box-shadow": ['unset', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['unset', '16px'],
      "w": "100%",
      "p": ['0px', '24px'],
      "font-family": "Roboto"
    }
  }, [_vm.isEmpty ? _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "justify": "center",
      "align-items": "center",
      "font-family": "Roboto"
    }
  }, [_c('EmptyCard', {
    attrs: {
      "imgalt": "Riwayat Program",
      "label": "Anda belum memiliki riwayat Pembelian"
    }
  }), _c('c-button', {
    attrs: {
      "as": "router-link",
      "to": "/program",
      "width": "269px",
      "height": ['50px', '50px'],
      "border-radius": "full",
      "border": "2px solid #008C81",
      "background": "#008C81",
      "font-size": ['14px', '18px'],
      "color": "#FFFFFF",
      "mb": "60px",
      "line-height": "21px"
    }
  }, [_vm._v(" Lihat Program ")])], 1) : _vm._e(), !_vm.isEmpty ? _c('c-flex', {
    attrs: {
      "margin": "auto",
      "flex-direction": "column",
      "w": "100%",
      "justify-content": "center",
      "align-content": "center",
      "p": "16px",
      "pos": "relative"
    }
  }, [_c('c-heading', {
    attrs: {
      "w": ['100%', '80%'],
      "display": ['none', 'block'],
      "margin": "auto",
      "align": "left",
      "font-size": "28px",
      "font-weight": "700",
      "margin-bottom": "20px"
    }
  }, [_vm._v(" Riwayat Program ")]), _c('c-flex', {
    attrs: {
      "w": ['100%', '80%'],
      "margin": "auto",
      "justify": "space-between",
      "mb": ['10px', '20px'],
      "gap": "5px"
    }
  }, _vm._l(_vm.menus, function (item, index) {
    return _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '&.active, &:focus, &:active': {
            color: '#FFFFFF',
            backgroundColor: '#008C81',
            border: '2px solid #008C81'
          }
        },
        expression: "{\n            '&.active, &:focus, &:active': {\n              color: '#FFFFFF',\n              backgroundColor: '#008C81',\n              border: '2px solid #008C81',\n            },\n          }"
      }],
      key: index,
      class: {
        active: item.status == _vm.active
      },
      attrs: {
        "color": "primary.400",
        "width": "100%",
        "height": ['34px', '50px'],
        "border-radius": "full",
        "border": "2px solid #008C81",
        "background": "#FFFFFF",
        "font-size": ['12px', '16px'],
        "font-weight": "500"
      },
      on: {
        "click": function click($event) {
          return _vm.selectMenu(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.menu) + " ")]);
  }), 1), _c('c-box', {
    attrs: {
      "display": "flex",
      "flex-direction": "column",
      "gap": ['10px', '20px']
    }
  }, _vm._l(_vm.filterPrograms, function (item) {
    var _item$product;

    return _c('ProgramHistoryCard', {
      key: item.id,
      attrs: {
        "title": item.name,
        "level": item.programService,
        "ahli-gizi": item.nutritionist,
        "duration": item.duration,
        "status": item.status,
        "date-start": item.startAt,
        "date-end": item.endAt,
        "price": item.productPrice,
        "coupon": item.couponName,
        "img": item === null || item === void 0 ? void 0 : (_item$product = item.product) === null || _item$product === void 0 ? void 0 : _item$product.photoUrl,
        "cursor-pointer": "pointer"
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.detailProgram(item);
        }
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }